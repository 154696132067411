// store
import { useState } from 'react';
import { useOptions } from '@/store/response';
// types
import { Option } from '@/types/response';
// mui
import { Box, MenuProps } from '@mui/material';
import { GridCellModes } from '@mui/x-data-grid';
// data grid
import { GridEditModes, GridRenderCellParams, useGridApiContext, useGridRootProps } from '@mui/x-data-grid-premium';
// components
import { SingleSelect as DropDownSingleSelect } from '../../Options/SingleSelect';
// utils
import { getValue } from '../../Options/utils';
// components
import { ConditionalField } from './ConditionalField';
import { RenderSelectedItem } from './RenderSelectedItem';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const CustomComponent = (props: GridRenderCellParams) => {
  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();
  const { id, field, colDef, value } = props;
  const columnDef = colDef as any;
  const options = useOptions(columnDef?.valueOptions ?? [], {
    leadingQuestionId: columnDef?.leadingQuestionId,
    rowId: id,
    questionId: columnDef?.field,
  });

  const [
    open,
    setOpen,
  ] = useState(true);

  const handleChange = async (option: Option | undefined, keepOpen?: boolean) => {
    const isValid = await apiRef.current.setEditCellValue({
      id,
      field,
      value: option ?? null,
    });

    if (isValid && rootProps.editMode === GridEditModes.Cell && !keepOpen) {
      if (apiRef.current.getCellMode(id, field) === GridCellModes.Edit) {
        apiRef.current.stopCellEditMode({ id, field });
      }
    }
  };

  const handleClose: MenuProps['onClose'] = async (_, reason) => {
    if (reason === 'backdropClick') {
      if (apiRef.current.getCellMode(id, field) === GridCellModes.Edit) {
        apiRef.current.stopCellEditMode({ id, field, ignoreModifications: true });
      }
    } else {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: apiRef.current.getCellValue(id, field) ?? null,
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (apiRef.current.getCellMode(id, field) === GridCellModes.Edit) {
        apiRef.current.stopCellEditMode({ id, field, ignoreModifications: true });
      }
      setOpen(false);
    }
  };

  const selected = getValue(value);

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxWidth: 500,
        overflow: 'auto',
      },
      onKeyDown: handleKeyDown,
    },
  };

  return (
    <DropDownSingleSelect
      value={value as Option}
      options={options}
      selectedOption={selected as string}
      disabled={false}
      onOptionChange={(option, keepOpen) => {
        handleChange(option, keepOpen);
      }}
      MenuProps={{
        ...menuProps,
        onClose: handleClose,
      }}
      sx={{
        height: '100%',
        '& .MuiSelect-select': {
          display: 'flex',
          alignItems: 'center',
          pl: 1,
        },
      }}
      autoFocus
      fullWidth
      open={open}
    />
  );
};

export const SingleSelect = (params: GridRenderCellParams) => <CustomComponent {...params} />;

export const RenderSingleSelectCell = (params: GridRenderCellParams<any, Option>) => {
  const { value, colDef } = params;
  const columnDef = colDef as any;
  const options: Option[] = columnDef?.valueOptions ?? [];
  const option = options.find(optionValue => value?.id === optionValue.id);

  const content = option ? (
    <RenderSelectedItem
      option={option}
      inCell={true}
    />
  ) : (
    <></>
  );

  return (
    <ConditionalField params={params}>
      <Box sx={{ width: '100%', align: 'center' }}>{content}</Box>
    </ConditionalField>
  );
};
