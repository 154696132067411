// types
// icons
import { Close, UploadFile } from '@mui/icons-material';
// mui
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { FilePreviewProps } from '../types';
// utils
import { fileData, formatFileSize } from '../utils';

export default function FilePreview({ file, disabled, sx, onRemove }: FilePreviewProps) {
  const { name = '', size, loading } = fileData(file);

  const deleteButton = !disabled ? (
    <IconButton
      edge="end"
      size="small"
      aria-label="remove-button"
      onClick={() => onRemove?.(file)}
    >
      <Close />
    </IconButton>
  ) : null;

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      sx={{
        p: 1,
        ...sx,
      }}
    >
      <UploadFile />

      <Stack
        flexGrow={1}
        sx={{ minWidth: 0 }}
      >
        <Typography
          variant="subtitle2"
          noWrap
        >
          {name}
        </Typography>

        <Typography
          variant="caption"
          sx={{ color: 'text.secondary' }}
        >
          {formatFileSize(size)}
        </Typography>
      </Stack>

      {loading ? (
        <CircularProgress
          size="small"
          color="secondary"
          aria-label="Loading"
        />
      ) : (
        deleteButton
      )}
    </Stack>
  );
}
