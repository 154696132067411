// types
import React from 'react';
import {
  LocalResponse,
  MatrixVariant,
  NumberOrString,
  NumericVariant,
  Organization,
  Question,
  QuestionClause,
  QuestionResponse,
  QuestionType,
  QuestionTypeMatrix,
  QuestionTypes,
  ResponseStatus,
  Row,
} from '@/types/response';
import { parseValidDate } from '@/utils/date';
// utils
import { keyFromIds } from '@/utils/response';
import { validateNumber } from '@/utils/validNumber';
import { Typography } from '@mui/material';
// data grid
import {
  GridCellParams,
  GridRenderCellParams,
  GridRowParams,
  GridRowsProp,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';
// custom cell renderer
import { ActionsCell } from './cells/ActionsCell';
import { RenderAttachment } from './cells/Attachment';
import { Currency } from './cells/Currency';
import { DateFieldEdit, RenderDate } from './cells/DateField';
import { DefaullCeltRenderer } from './cells/DefaultCellRenderer';
import { EditTextArea } from './cells/EditTextArea';
import { GroupedOptions, RenderGroupedOptionsCell } from './cells/GroupedOptions';
import { MultiSelect, RenderMultiSelectCell } from './cells/MultiSelect';
import { NumberField, NumberFieldEdit } from './cells/NumberField';
import { RenderSingleSelectCell, SingleSelect } from './cells/SingleSelect';
import { ColDef, GridProps } from './types';

// const
const COLUMNS_MIN_WIDTH = 160;
const COLUMNS_FOR_ROW_NAME_MIN_WIDTH = 100;
const COLUMNS_DYNAMIC_WIDTH = 100;
// map question type to grid column type
const toColumnTypDef = (question: Question, requestingAuthorities: Organization[]) => {
  const type = question.type?.__typename;

  switch (type) {
    case 'QuestionTypeRichText':
      return {
        type: 'string',
        align: 'left',
        renderCell: DefaullCeltRenderer,
        renderEditCell: EditTextArea,
      };
    case 'QuestionTypeNumeric': {
      if (question.type.numericVariant === NumericVariant.Currency)
        return {
          type: 'number',
          align: 'left',
          renderCell: Currency,
          // data-grid has a default valueParser for numeric fields
          // that would treat null values as numbers via Number(value)
          // for value = null Number(value) returns 0
          // for other values it might return NaN
          // given we are handling the logic ourselves
          // this function  just return the value as it is stored in the cell by our component
          valueParser: (value: any) => {
            if (value === undefined || value === null || value === '') return value;
            else {
              return Number(value);
            }
          },
        };
      else
        return {
          type: 'number',
          rules: question.type.rules,
          blankOptions: question.blankOptions,
          renderEditCell: NumberFieldEdit,
          renderCell: NumberField,
          // data-grid has a default valueParser for numeric fields
          // that would treat null values as numbers via Number(value)
          // for value = null Number(value) returns 0
          // for other values it might return NaN
          // given we are handling the logic ourselves
          // this function  just return the value as it is stored in the cell by our component
          valueParser: (value: any) => {
            if (value === undefined || value === null || value === '') return value;
            else {
              return Number(value);
            }
          },
          align: 'left',
        };
    }

    case 'QuestionTypeDate':
      return {
        type: 'date',
        valueGetter: (params: GridRenderCellParams) => {
          if (params.value) {
            const date = parseValidDate(params.value);
            if (!date) return '';
            return date;
          }
          return '';
        },
        align: 'left',
        renderCell: RenderDate,
        renderEditCell: DateFieldEdit,
      };
    case 'QuestionTypeSingleSelect':
      return {
        type: 'singleSelect',
        valueOptions: question.requestingAuthoritiesEnabled ? requestingAuthorities : question.type.options,
        renderEditCell: SingleSelect,
        renderCell: RenderSingleSelectCell,
      };
    case 'QuestionTypeMultiSelect':
      return {
        valueOptions: question.type.options,
        renderEditCell: MultiSelect,
        renderCell: RenderMultiSelectCell,
      };
    case 'QuestionTypeGroupedOptions':
      return {
        valueOptions: question.type.optionGroups,
        renderEditCell: GroupedOptions,
        renderCell: RenderGroupedOptionsCell,
      };
    case 'QuestionTypeAttachment':
      return {
        type: 'string',
        editable: false,
        renderCell: RenderAttachment,
      };
    default:
      return {
        type: 'string',
        renderCell: DefaullCeltRenderer,
      };
  }
};

// define grid columns based on questions props
const toGridColDef = (
  leadingQuestionId: NumberOrString,
  columns: Question[],
  isDynamic: boolean,
  submitted: boolean,
  requestingAuthorities: Organization[],
  rowTitle?: string | null,
): ColDef[] => {
  const colDef: ColDef[] = [
    columnForRowName(leadingQuestionId, rowTitle),
  ];

  columns.forEach(column => {
    colDef.push({
      field: column.id,
      headerName: column.text,
      minWidth: COLUMNS_MIN_WIDTH,
      columnType: column.type,
      editable: true,
      isMandatory: column.isMandatory,
      headerAlign: 'left',
      leadingQuestionId,
      ...(toColumnTypDef(column, requestingAuthorities) as any),
      ...(submitted && {
        editable: false,
        sortable: false,
        filterable: false,
      }),

      popupHelpText: column.popupHelpText,
    });
  });

  if (isDynamic)
    colDef.push({
      field: '',
      type: 'actions',
      headerName: 'Actions',
      width: COLUMNS_DYNAMIC_WIDTH,
      getActions: (params: GridRowParams) => {
        return [
          <ActionsCell
            key={params.row.id}
            {...params}
          />,
        ];
      },
    } as any);

  return colDef;
};

// transform complex question to DataGrid input props
export const toDataGridProps = (data: {
  leadingQuestionId: NumberOrString;
  rows: Row[];
  columns: Question[];
  responses: Record<NumberOrString, LocalResponse>;
  isDynamic: boolean;
  submitted: boolean;
  authoritiesData: {
    requestingAuthorities: Organization[];
    rowTitle?: string | null;
  };
}): GridProps => {
  const gridRows: Record<NumberOrString, GridValidRowModel>[] = [];
  const { leadingQuestionId, rows, columns, responses, isDynamic, submitted, authoritiesData } = data;
  const { requestingAuthorities, rowTitle } = authoritiesData;
  rows.forEach(row => {
    const gridRow: GridValidRowModel = {
      id: row.id,
      name: row?.title,
      isCustom: row.isCustom,
    };

    columns.forEach(question => {
      const key = keyFromIds(leadingQuestionId, row.id, question.id);
      gridRow[question.id] = responses[key]?.content;
    });

    gridRows.push(gridRow);
  });

  return {
    columns: toGridColDef(leadingQuestionId, columns, isDynamic, submitted, requestingAuthorities, rowTitle),
    rows: gridRows as GridRowsProp,
  };
};

export const toResponse = (leadingQuestionId: NumberOrString, row: GridValidRowModel, col: ColDef) => {
  let content = Array.isArray(row[col.field]) && row[col.field].length == 0 ? null : row[col.field] ?? null;
  const rowId = row.id;
  const questionId = col.field;
  content = validateContent(content, col.columnType, col.rules);

  return {
    leadingQuestionId,
    rowId,
    questionId,
    content: content,
    status: content ? ResponseStatus.Answered : ResponseStatus.Unanswered,
  };
};

export const isCellEditable = (
  params: Partial<GridCellParams>,
  leadingQuestionId: NumberOrString,
  expressions: Map<NumberOrString, NumberOrString[]>,
  clauses: Map<NumberOrString, QuestionClause>,
) => {
  const rowId = params.row.id;
  const questionId = params.field;
  const key = keyFromIds(leadingQuestionId, rowId, questionId);
  if (expressions.has(key)) return false;
  if (clauses.has(key)) return clauses.get(key)?.display !== false;
  return true;
};

export const isCellAutoCalc = (
  params: GridCellParams,
  leadingQuestionId: NumberOrString,
  expressions: Map<NumberOrString, NumberOrString[]>,
) => {
  const rowId = params.row.id;
  const questionId = params.field;
  const key = keyFromIds(leadingQuestionId, rowId, questionId);
  if (expressions.has(key)) return true;

  return false;
};

// column used to display row name
const columnForRowName = (leadingQuestionId: NumberOrString, rowTitle?: string | null) => {
  return {
    field: `${leadingQuestionId}-row-name`,
    type: 'string',
    minWidth: COLUMNS_FOR_ROW_NAME_MIN_WIDTH,
    headerName: `${rowTitle ?? ''}`,
    editable: false,
    renderCell: (params: GridRenderCellParams<any, Date>) => {
      return <Typography>{params.row.name}</Typography>;
    },
  } as any;
};

export const validateContent = (value: any, columnType: QuestionType, rules?: any): any => {
  switch (columnType?.__typename) {
    case 'QuestionTypeDate':
      return parseValidDate(value);
    case 'QuestionTypeNumeric': {
      if (value === undefined || value === null || value === '') {
        return value;
      }
      const numericValue = validateNumber(value, rules?.lo ?? 0, rules?.hi ?? Infinity);
      return numericValue === 0 ? JSON.stringify(0) : numericValue;
    }
    default:
      return value === 0 ? JSON.stringify(0) : value;
  }
};

export const isAtLeastOneMandatoryCellVisible = (
  question: Question,
  rows: Row[],
  columns: Question[],
  expressions: Map<NumberOrString, NumberOrString[]>,
  clauses: Map<NumberOrString, QuestionClause>,
): boolean => {
  const mandatoryColumns = columns?.filter(column => column.isMandatory === true);
  if (mandatoryColumns.length <= 0 || rows.length <= 0) {
    return false;
  }
  return rows.some(row => {
    return mandatoryColumns.some(mandatoryColumn => {
      return isCellEditable({ row: { id: row.id }, field: mandatoryColumn.id }, question.id, expressions, clauses);
    });
  });
};
export const isQuestionTypeMatrix = (questionType: QuestionType): questionType is QuestionTypeMatrix =>
  questionType.type === QuestionTypes.Matrix;

export const isMatrixTableVariant = (questionType: QuestionTypeMatrix): boolean =>
  questionType.matrixVariant === MatrixVariant.Table;

export const isMatrixTableQuestion = (question: Question): boolean => {
  return question && isQuestionTypeMatrix(question?.type) && isMatrixTableVariant(question.type);
};
export const isMatrixTableAndHasMandatoryCellsVisibile = (
  question: Question,
  rows: Row[],
  columns: Question[],
  expressions: Map<NumberOrString, NumberOrString[]>,
  clauses: Map<NumberOrString, QuestionClause>,
): boolean => {
  return (
    isMatrixTableQuestion(question) && isAtLeastOneMandatoryCellVisible(question, rows, columns, expressions, clauses)
  );
};

export const getMandatoryColumns = (columns: Question[]): Question[] => {
  return columns.filter(column => column.isMandatory);
};

export const getVisibleColumns = (
  clauses: Map<NumberOrString, QuestionClause>,
  leadingQuestionId: string,
  columns: Question[],
): Question[] => {
  return columns.filter(column => clauses.get(keyFromIds(leadingQuestionId, '*', column.id))?.display !== false);
};

export const getVisibleRows = (
  clauses: Map<NumberOrString, QuestionClause>,
  leadingQuestionId: string,
  rows: Row[],
): Row[] => {
  return rows.filter(row => clauses.get(keyFromIds(leadingQuestionId, row.id))?.display !== false);
};
export const isCellEditableAndNotAnswered = (
  clauses: Map<NumberOrString, QuestionClause>,
  expressions: Map<NumberOrString, NumberOrString[]>,
  responses: Record<NumberOrString, QuestionResponse>,
  leadingQuestionId: string,
  rowId: string,
  columnId: string,
): boolean => {
  const key = keyFromIds(leadingQuestionId, rowId, columnId);
  if (expressions.has(key)) return false;
  const isUnlocked = clauses.get(key)?.display;
  const responseStatus = responses[key]?.status;
  if (isUnlocked !== false && responseStatus != ResponseStatus.Answered) {
    return true;
  }
  return false;
};

export const isAtLeastOneEditableCellNotAnswered = (
  clauses: Map<NumberOrString, QuestionClause>,
  expressions: Map<NumberOrString, NumberOrString[]>,
  responses: Record<NumberOrString, QuestionResponse>,
  leadingQuestionId: string,
  rows: Row[],
  columns: Question[],
): boolean => {
  return rows.some(row => {
    return columns.some(column => {
      return isCellEditableAndNotAnswered(clauses, expressions, responses, leadingQuestionId, row.id, column.id);
    });
  });
};
export const isMatrixTableAndMandatoryColumnsAreNotFullyAnswered = (
  clauses: Map<NumberOrString, QuestionClause>,
  expressions: Map<NumberOrString, NumberOrString[]>,
  responses: Record<NumberOrString, QuestionResponse>,
  question: Question,
): boolean => {
  if (!isQuestionTypeMatrix(question.type)) {
    return false;
  }
  if (!isMatrixTableVariant(question.type)) {
    return false;
  }
  const allQuestionRows = question.type.rows;
  const allQuestionColumns = question.type.columns;
  const visibleColumns = getVisibleColumns(clauses, question.id, allQuestionColumns);
  const mandatoryColumns = getMandatoryColumns(visibleColumns);
  if (mandatoryColumns.length <= 0) {
    return false;
  }
  const visibleRows = getVisibleRows(clauses, question.id, allQuestionRows);
  if (visibleRows.length <= 0) {
    return false;
  }
  return isAtLeastOneEditableCellNotAnswered(
    clauses,
    expressions,
    responses,
    question.id,
    visibleRows,
    mandatoryColumns,
  );
};
