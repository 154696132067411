// next 18
import { useTranslation } from 'react-i18next';
// types
import { NumberOrString, ResponseAttachment, ResponseStatus } from '@/types/response';
// mui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
// components
import QuestionUpload from '../../QuestionUpload';

type DialogProps = {
  attachments: ResponseAttachment[];
  questionId: NumberOrString;
  rowId: NumberOrString;
  open: boolean;
  onChange: (status: ResponseStatus, attachments?: ResponseAttachment[]) => void;
  onClose: () => void;
};

export const AttachementDialog = ({ open, questionId, rowId, attachments, onChange, onClose }: DialogProps) => {
  const { t } = useTranslation('questionnaire', {
    keyPrefix: 'questionnaire.response.question.complex',
  });
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{t('uploadFile')}</DialogTitle>
      <DialogContent>
        <QuestionUpload
          attachments={attachments}
          onChange={(_, status, f) => onChange(status, f)}
          id={questionId}
          rowId={rowId}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onClose}
        >
          {t('done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
