export const isValidNumber = (value: string, allowedDecimals: number) => {
  if (value === '') {
    return true;
  }
  const regex = new RegExp(`^-?\\d+(\\.\\d{0,${allowedDecimals}})?$`);
  return regex.test(value);
};

export const validateNumber = (value: number, min?: number, max?: number): number => {
  if (typeof value !== 'number') throw new Error('ValidationError');
  if (min != undefined && value < min) throw new Error('ValidationError');
  if (max != undefined && value > max) throw new Error('ValidationError');
  return value;
};
