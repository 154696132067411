import { debounce } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useMetadataFetched, useResponseActions } from '@/store/response';
import { QuestionnaireMetadata } from '@/types/response';
import axiosInstance from '@/utils/axios-client';

const getMetadata = async () => {
  const response = await axiosInstance.get<QuestionnaireMetadata>('/questionnaire/portal/metadata');
  return response.data;
};

export function useGetMetadata(shouldFetch: boolean) {
  const [
    loading,
    setLoading,
  ] = useState(false);
  const [
    error,
    setError,
  ] = useState();

  const { setMetadata } = useResponseActions();
  const metadata = useMetadataFetched();

  const fetchMetadata = useCallback(async () => {
    setLoading(true);
    setError(undefined);
    try {
      const data = await getMetadata();
      setMetadata(data);
    } catch (error: any) {
      setError(error);
      console.error('error fetching metadata:', error);
      enqueueSnackbar('Could not retrieve metadata.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [
    setMetadata,
    setLoading,
    setError,
  ]);

  const debouncedFetchAllMetadata = useCallback(debounce(fetchMetadata, 300), [
    fetchMetadata,
  ]);

  useEffect(() => {
    if (shouldFetch && !metadata) {
      debouncedFetchAllMetadata();
    }
  }, [
    shouldFetch,
    metadata,
    fetchMetadata,
  ]);

  return { loading, data: metadata, error, refetch: debouncedFetchAllMetadata };
}
