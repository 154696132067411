// types
import { ResourceKey } from 'i18next';
import { ResponseStatus } from '@/types/response';
// icons
import { CircleOutlined, DoneAllOutlined, DoneOutlined, SkipNext, Timelapse } from '@mui/icons-material';
import { Theme, alpha } from '@mui/material';

// map response status to translation keys
export const responseStatus: Record<ResponseStatus, ResourceKey> = {
  [ResponseStatus.Answered]: 'answered',
  [ResponseStatus.Skipped]: 'skipped',
  [ResponseStatus.Progress]: 'progress',
  [ResponseStatus.Reviewed]: 'reviewed',
  [ResponseStatus.Unanswered]: 'unanswered',
  [ResponseStatus.Conditional]: 'conditional',
};

// map response status to icons
export const responseStatusIcon: Record<ResponseStatus, JSX.Element | undefined> = {
  [ResponseStatus.Answered]: <DoneOutlined />,
  [ResponseStatus.Skipped]: <SkipNext />,
  [ResponseStatus.Progress]: <Timelapse />,
  [ResponseStatus.Reviewed]: <DoneAllOutlined />,
  [ResponseStatus.Unanswered]: <CircleOutlined />,
  [ResponseStatus.Conditional]: undefined,
};

// map response status to colors
export const getColors = (theme: Theme, status?: ResponseStatus) => {
  let color = theme.palette.secondary.dark;
  let background = undefined;

  if (status) {
    if (
      [
        ResponseStatus.Answered,
        ResponseStatus.Reviewed,
      ].includes(status)
    ) {
      color = theme.palette.success.dark;
      background = alpha(theme.palette.success.light, 0.3);
    } else if (status === ResponseStatus.Progress) {
      color = theme.palette.warning.main;
      background = alpha(theme.palette.warning.main, 0.12);
    } else if (status === ResponseStatus.Unanswered) {
      color = theme.palette.error.main;
      background = alpha(theme.palette.error.main, 0.12);
    }
  }

  return { color, background };
};
