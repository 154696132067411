import axios from 'axios';
import { tokenVar } from './apollo-client';

const axiosInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_RESPONSE_BACKEND_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  config => {
    const authToken = tokenVar();
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
