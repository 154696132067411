// types
// axios
import axios from 'axios';
// 3rd
import { FileRejection } from 'react-dropzone';
import { ExtendFile, NumberOrString } from '@/types/response';
// config
import { api_mocking, responseConfig } from '@config';

export function fileData(file: ExtendFile) {
  // File
  return {
    key: file.attachmentId || `${file.name}-${Date.now()}`,
    name: file.name,
    size: file.fileSize || file.size,
    type: file.type,
    loading: file.loading,
  };
}

export function formatFileSize(fileSize: number): string {
  if (fileSize === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const sizes = [
    'Bytes',
    'KB',
    'MB',
    'GB',
    'TB',
  ];
  const i = Math.floor(Math.log(fileSize) / Math.log(k));

  return parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export async function uploadFile(
  file: ExtendFile,
  questionId: NumberOrString,
  rowId: NumberOrString | undefined,
  isPreview?: boolean,
): Promise<ExtendFile | FileRejection> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('questionId', `${questionId}`);
  rowId && formData.append('rowId', `${rowId}`);

  // skip the API call when mocking is enabled or when the preview mode is active
  if (isPreview || api_mocking === 'enabled') {
    file.attachmentId = Date.now();
    return file;
  }

  try {
    const { data } = await axios.post(`${responseConfig.backend_url}/response/file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    file.attachmentId = data.id;
    return file;
  } catch (e) {
    console.error(e);

    return {
      file,
      errors: [
        { message: `Failed to upload file ${file.name}`, code: '500' },
      ],
    };
  }
}

export async function uploadMultipleFiles(
  files: ExtendFile[],
  questionId: NumberOrString,
  rowId: NumberOrString | undefined,
  isPreview?: boolean,
): Promise<{ accepted: ExtendFile[]; rejected: FileRejection[] }> {
  const responses = [];

  for (const file of files) {
    const uploadPromise = await uploadFile(file, questionId, rowId, isPreview);
    responses.push(uploadPromise);
  }

  return {
    accepted: responses.filter(file => !isRejected(file)) as ExtendFile[],
    rejected: responses.filter(file => isRejected(file)) as FileRejection[],
  };
}

function isRejected(file: ExtendFile | FileRejection) {
  return (file as FileRejection).errors;
}
