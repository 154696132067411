// store
import { useDefaultCurrency } from '@/store/response';
import { Box, Typography } from '@mui/material';
// data grid
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
// components
import { ConditionalField } from './ConditionalField';

export const CustomComponent = (props: GridRenderCellParams) => {
  const defaultCurrency = useDefaultCurrency();
  const { value, colDef } = props;
  const formatted =
    isNaN(value) || value === null || value === undefined || value === ''
      ? null
      : Number(value).toLocaleString('en-GB', {
          style: 'currency',
          currency: defaultCurrency,
        });

  return (
    <Box
      sx={{
        width: colDef.width ?? '100%',
        maxWidth: colDef.computedWidth,
        overflowX: 'hidden',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Typography
        sx={{
          display: 'block',
          flexWrap: 'wrap',
          overflow: 'hidden',
          WebkitBoxOrient: 'horizontal',
          textOverflow: 'ellipsis',
        }}
      >
        {formatted}
      </Typography>
    </Box>
  );
};

// TODO [hmachaao]: to be removed (currency type will be merged into number type)

export const Currency = (params: GridRenderCellParams) => (
  <ConditionalField params={params}>
    <CustomComponent {...params} />
  </ConditionalField>
);
