// lodash
import { flatMap, keyBy } from 'lodash';
// i18n
import { useTranslation } from 'next-i18next';
// react
import { useRef, useState } from 'react';
// types
import { GroupedOption, Option, QuestionGroupedOption } from '@/types/response';
// mui
import { FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import { RenderSelectedItem } from '../GridView/cells/RenderSelectedItem';
// components
import { OptionInputField } from './OptionInputField';
// utils
import { ListSubheader, flattenedOptions, getOptionById } from './utils';

type Props = {
  value: QuestionGroupedOption[];
  groupedOptions: GroupedOption[];
  selectedOption: string;
  disabled: boolean;
  onOptionChange: (selected: Option | undefined) => void;
  onClose?: () => void;
} & SelectProps;

export const GroupedSingleSelect = ({
  value,
  groupedOptions,
  selectedOption,
  disabled,
  onOptionChange,
  onClose,
  ...rest
}: Props) => {
  const { t } = useTranslation('questionnaire', {
    keyPrefix: 'questionnaire.placeholders',
  });
  const [
    open,
    setOpen,
  ] = useState(false);

  const optionRef = useRef<Option>();

  const options = flatMap(groupedOptions, 'options') as Option[];
  const optionsById = keyBy(flatMap(value, 'options') as Option[], 'id');

  const handleClose = () => {
    if (optionRef.current?.isOther) setOpen(true);
    else setOpen(false);
    optionRef.current = undefined;
  };

  const handleOpen = () => {
    setOpen(true);
    optionRef.current = undefined;
  };

  const handleMenuItemClick = (optionId: string) => {
    if (optionId === selectedOption || selectedOption?.[0] === optionId) {
      onOptionChange(undefined);
      setOpen(false);
      onClose?.();
    } else {
      const option = getOptionById(options, optionId);
      onOptionChange(option);

      if (option?.isOther) optionRef.current = option;
      else {
        optionRef.current = undefined;
        setOpen(false);
        onClose?.();
      }
    }
  };

  const content = () => {
    const el: JSX.Element[] = [];
    // iterate through grouped options
    groupedOptions.forEach(group => {
      // group header
      el.push(
        <ListSubheader
          muiSkipListHighlight
          disableSticky
          key={group.id}
          sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
        >
          {group.name} ({group.options?.length})
        </ListSubheader>,
      );
      // options within the group
      group.options.forEach(option => {
        const checked = !!optionsById[option.id];
        el.push(
          <MenuItem
            key={option.id}
            value={option.id}
            onClick={() => handleMenuItemClick(option.id)}
            sx={{ textOverflow: 'ellipsis', width: '100%' }}
          >
            <ListItemText
              primary={option.name}
              sx={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            />
          </MenuItem>,
        );
        if (option.isOther && checked)
          el.push(
            <OptionInputField
              key={`other-${option.id}`}
              description={optionsById[option.id]?.description ?? ''}
              option={option}
              onOptionEdit={option => {
                onOptionChange(option);
              }}
            />,
          );
      });
    });
    return el;
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{t('singleSelect')}</InputLabel>
      <Select
        label={t('singleSelect')}
        value={selectedOption}
        onChange={(e: SelectChangeEvent<unknown>) => handleMenuItemClick(e.target.value as string)}
        onClose={handleClose}
        onOpen={handleOpen}
        disabled={disabled}
        renderValue={value => {
          const option = getOptionById(flattenedOptions(groupedOptions), value as string);
          return (
            option && (
              <RenderSelectedItem
                option={option}
                inCell={false}
              />
            )
          );
        }}
        sx={{ flex: 1, width: '100%' }}
        data-testid="question-grouped-options"
        open={open}
        {...rest}
      >
        {content()}
      </Select>
    </FormControl>
  );
};
