// types
import { flatMap, keyBy } from 'lodash';
// i18n
import { useTranslation } from 'next-i18next';
import { GroupedOption, Option, QuestionGroupedOption } from '@/types/response';
// mui
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectProps } from '@mui/material';
import { OptionInputField } from './OptionInputField';
// utils
import { ListSubheader, getOptionById, getOptionsByIds, renderValues, toResponse } from './utils';

type Props = {
  value: QuestionGroupedOption[];
  groupedOptions: GroupedOption[];
  selectedOptions: string[];
  disabled: boolean;
  onOptionChange: (selected: Option[], lastSelected?: Option) => void;
} & SelectProps;

export const GroupedMultiSelect = ({
  value,
  groupedOptions,
  selectedOptions,
  disabled,
  onOptionChange,
  ...rest
}: Props) => {
  const { t } = useTranslation('questionnaire', {
    keyPrefix: 'questionnaire.placeholders',
  });
  const options = flatMap(groupedOptions, 'options') as Option[];
  const optionsById = keyBy(flatMap(value, 'options') as Option[], 'id');

  const content = () => {
    const el: JSX.Element[] = [];

    // iterate through grouped options
    groupedOptions.forEach(group => {
      // group header
      el.push(
        <ListSubheader
          muiSkipListHighlight
          disableSticky
          key={group.id}
          sx={{
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {group.name} ({group.options?.length})
        </ListSubheader>,
      );
      // options within the group
      group.options.forEach(option => {
        const checked = !!optionsById[option.id];
        el.push(
          <MenuItem
            key={option.id}
            value={option.id}
          >
            <Checkbox
              size="small"
              checked={checked}
            />
            <ListItemText
              primary={option.name}
              sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
            />
          </MenuItem>,
        );
        if (option.isOther && checked)
          el.push(
            <OptionInputField
              key={`other-${option.id}`}
              description={optionsById[option.id]?.description ?? ''}
              option={option}
              onOptionEdit={option => {
                const opts = { ...optionsById, [option.id]: option };
                onOptionChange(toResponse(options, opts, selectedOptions), option);
              }}
            />,
          );
      });
    });
    return el;
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{t('multiSelect')}</InputLabel>
      <Select
        label={t('multiSelect')}
        value={selectedOptions}
        onChange={e => {
          const selected = e.target.value as string[];
          const lastSelected = getOptionById(options, selected?.[selected?.length - 1]);
          onOptionChange(toResponse(options, optionsById, selected), lastSelected);
        }}
        disabled={disabled}
        renderValue={value => {
          return renderValues(getOptionsByIds(options, value as string[]));
        }}
        sx={{ flex: 1 }}
        multiple
        data-testid="question-grouped-options"
        multiline={false}
        {...rest}
      >
        {content()}
      </Select>
    </FormControl>
  );
};
