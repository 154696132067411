// types
import { ResourceKey } from 'i18next';
import { BlankOptions, Expression, NumberOrString, QuestionClause, QuestionResponse } from '@/types/response';
import { isCellLocked, keyFromIds } from './response';

const operators = new Set([
  '+',
  '-',
  '*',
  '/',
]);

function transExpression(input: NumberOrString[]): NumberOrString[] {
  const transformedArray = input.map(item => {
    if (Array.isArray(item)) {
      return item.join('-');
    } else {
      return item;
    }
  });
  return transformedArray;
}

const parseAutoCalcExpression = (exporession: string) => {
  return transExpression(JSON.parse(exporession));
};

const isNullOrNaN = (value: any) => value === undefined || isNaN(value);

const transformOperand = (operand: any) => (isNullOrNaN(operand) || operand === '' ? 0 : operand);

export const evaluatePostfixExpression = (
  responses: Record<NumberOrString, QuestionResponse>,
  expression: NumberOrString[] | undefined,
  clauses: Map<NumberOrString, QuestionClause>,
  blankOptions: number = -1,
): {
  content: string;
  error: ResourceKey;
} => {
  const stack: number[] = [];

  if (!expression || Object.keys(responses).length === 0) return { content: '', error: '' };

  for (const token of expression.filter(exp => exp)) {
    if (isCellLocked(token, clauses)) {
      blankOptions = BlankOptions.IGNORE;
      continue;
    }
    if (!operators.has(token as string)) {
      // if token is constant
      if (typeof token === 'number') stack.push(token);
      // if the token is an id, push it's value onto the stack
      else stack.push(parseFloat(responses[token]?.content));
    } else {
      // if the token is an operator, pop the top two operands from the stack,
      // apply the operator, and push the result back onto the stack.
      let operand2 = stack.pop() as number;
      let operand1 = stack.pop() as number;

      if (
        [
          BlankOptions.IGNORE,
          BlankOptions.TREAT_AS_ZERO,
        ].includes(blankOptions)
      ) {
        operand1 = transformOperand(operand1);
        operand2 = transformOperand(operand2);
      }

      if (isNullOrNaN(operand1) || isNullOrNaN(operand2)) {
        return { content: '', error: '' };
      }

      switch (token) {
        case '+':
          stack.push(operand1 + operand2);
          break;
        case '-':
          stack.push(operand1 - operand2);
          break;
        case '*':
          stack.push(operand1 * operand2);
          break;
        case '/':
          if (operand2 == 0) return { content: '', error: 'divisionByZero' };
          stack.push(operand1 / operand2);
          break;
        default:
          // we should never end up here !
          throw new Error('Invalid operator');
      }
    }
  }

  // the stack should contain the final result
  if (stack.length !== 1) return { content: '', error: '' };

  return { content: stack[0].toString(), error: '' };
};

export const toExpressions = (
  data: Expression[],
  idSet: Set<NumberOrString>,
  dynamicRowMapping: Map<NumberOrString, { id: string; name: string }[]>,
): Map<NumberOrString, NumberOrString[]> => {
  const expressions = new Map<NumberOrString, NumberOrString[]>();

  data?.forEach(exp => {
    const { leadingQuestionId, rowId, questionId, postfix } = exp;
    if (idSet.has(keyFromIds(leadingQuestionId)) || idSet.has(keyFromIds(questionId))) {
      expressions.set(keyFromIds(leadingQuestionId, rowId, questionId), parseAutoCalcExpression(postfix));

      //Copying expressions from the first row to all custom rows

      if (leadingQuestionId && rowId) {
        dynamicRowMapping?.get(leadingQuestionId)?.forEach(customRow => {
          const postfixString = JSON.stringify(postfix);

          const customPostfix = postfixString.replace(new RegExp(rowId, 'g'), customRow?.id);
          expressions.set(
            keyFromIds(leadingQuestionId, customRow?.id, questionId),
            parseAutoCalcExpression(JSON.parse(customPostfix)),
          );
        });
      }
    }
  });

  return expressions;
};
