// useSnackbar
import { useSnackbar } from 'notistack';
// api
import { useDeleteRow, useDeleteRowPreviewMode } from '@/graphql/response/mutations/deleteRow';
import { useMetadata } from '@/hooks/response/useMetadata';
import { useIsSkipSave } from '@/store/response';
// mui icons
import { DeleteOutlined } from '@mui/icons-material';
// data grid
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-premium';
// provider
import { useRowLogic } from '../provider/GridViewProvider';

export const ActionsCell = ({ row }: GridRowParams) => {
  const { questionId, onLoading } = useRowLogic();
  const { enqueueSnackbar } = useSnackbar();
  const { submitted } = useMetadata();
  const isPreview = useIsSkipSave();
  const { deleteRowPreview } = useDeleteRowPreviewMode(questionId);

  const [
    deleteRow,
  ] = useDeleteRow(questionId, row.id);

  const handleDeleteRow = async () => {
    try {
      onLoading(true);
      if (isPreview) {
        deleteRowPreview(row.id);
      } else {
        await deleteRow();
      }
    } catch (e) {
      if (e instanceof Error) {
        console.error(e.message);
        enqueueSnackbar('Could not delete row');
      } else {
        // Handle the case where the error is not an Error instance
        console.error('An unexpected error occurred', e);
        enqueueSnackbar('Could not delete row due to an unexpected error', { variant: 'error' });
      }
    } finally {
      onLoading(false);
    }
  };

  const isCustom = row?.isCustom;

  return (
    <GridActionsCellItem
      data-testid="delete-row"
      icon={<DeleteOutlined />}
      label="Delete"
      onClick={handleDeleteRow}
      disabled={!isCustom || submitted}
    />
  );
};
