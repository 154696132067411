// store
import { useState } from 'react';
import { useOptions } from '@/store/response';
// types
import { Option } from '@/types/response';
// mui
import { Box, MenuProps } from '@mui/material';
import { GridCellModes } from '@mui/x-data-grid';
// data grid
import { GridRenderCellParams, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { MultiSelect as DropDownMultiSelect } from '../../Options/MultiSelect';
// utils
import { getValue } from '../../Options/utils';
// components
import { ConditionalField } from './ConditionalField';
import { RenderSelectedItem } from './RenderSelectedItem';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const CustomComponent = (props: GridRenderCellParams) => {
  const apiRef = useGridApiContext();
  const { id, value, field, colDef } = props;
  const columnDef = colDef as any;
  const options = useOptions(columnDef?.valueOptions ?? [], {
    leadingQuestionId: columnDef?.leadingQuestionId,
    rowId: id,
    questionId: columnDef?.field,
  });

  const handleChange = async (opts: Option[]) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: opts,
    });
  };
  const [
    open,
    setOpen,
  ] = useState(true);

  const handleClose: MenuProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      if (apiRef.current.getCellMode(id, field) === GridCellModes.Edit) {
        apiRef.current.stopCellEditMode({ id, field });
      }
    }
  };

  const selected = getValue(value, true) as string[];

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (apiRef.current.getCellMode(id, field) === GridCellModes.Edit) {
        apiRef.current.stopCellEditMode({ id, field, ignoreModifications: true });
      }
      setOpen(false);
    }
  };
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxWidth: 500,
        overflow: 'auto',
      },
      onKeyDown: handleKeyDown,
    },
  };

  return (
    <DropDownMultiSelect
      value={value as Option[]}
      options={options}
      selectedOptions={selected}
      disabled={false}
      onOptionChange={options => {
        handleChange(options);
      }}
      autoFocus
      fullWidth
      open={open}
      MenuProps={{
        ...menuProps,
        onClose: handleClose,
      }}
      sx={{
        height: '100%',
        '& .MuiSelect-select': {
          display: 'flex',
          alignItems: 'center',
          textOverflow: 'ellipsis',
          pl: 1,
        },
      }}
    />
  );
};

export const MultiSelect = (params: GridRenderEditCellParams<any, string>) => <CustomComponent {...params} />;

export const RenderMultiSelectCell = (params: GridRenderCellParams<any, Option[]>) => {
  const { value, colDef } = params;
  const columnDef = colDef as any;

  return (
    <ConditionalField params={params}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {value?.map(
          option =>
            option && (
              <RenderSelectedItem
                key={option.id}
                option={columnDef?.valueOptions.find((val: Option) => val.id === option.id)}
                inCell={true}
              />
            ),
        )}
      </Box>
    </ConditionalField>
  );
};
