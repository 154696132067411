// react
import { useState } from 'react';
// types
import { Option } from '@/types/response';
// mui
import { Box, TextField } from '@mui/material';

type Props = {
  description: string;
  option: Option;
  onOptionEdit: (option: Option) => void;
};

export const OptionInputField = ({ description, option, onOptionEdit }: Props) => {
  const [
    value,
    setValue,
  ] = useState(description);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleBlur = () => {
    onOptionEdit({ ...option, description: value });
  };

  return (
    <Box sx={{ width: '100%', px: 2, py: 1 }}>
      <TextField
        hiddenLabel
        multiline
        value={value}
        onKeyDown={e => {
          e.stopPropagation();
        }}
        onChange={handleChange}
        onBlur={handleBlur}
        sx={{ width: 1 }}
        inputProps={{ maxLength: 250 }}
      />
    </Box>
  );
};
