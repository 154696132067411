// types
// mui
import { Stack } from '@mui/material';
import { UploadProps } from '../types';
// utils
import { fileData } from '../utils';
// components
import FilePreview from './FilePreview';

export default function MultiFilePreview({ files, onRemove, sx, disabled }: UploadProps) {
  if (!files?.length) {
    return null;
  }

  return (
    <Stack>
      {files.map(file => {
        const { key } = fileData(file);
        return (
          <FilePreview
            key={key}
            file={file}
            disabled={disabled}
            onRemove={onRemove}
            sx={sx}
          />
        );
      })}
    </Stack>
  );
}
