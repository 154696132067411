// lodahs
import { flatMap } from 'lodash';
import { useState } from 'react';
// store
import { useGroupedOptions } from '@/store/response';
// types
import {
  GroupedOption,
  GroupedOptionsVariant,
  Option,
  QuestionGroupedOption,
  QuestionTypeGroupedOptions,
} from '@/types/response';
// mui
import { Box, MenuProps } from '@mui/material';
import { GridCellModes } from '@mui/x-data-grid';
// data grid
import { GridRenderCellParams, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
// components
import { GroupedMultiSelect } from '../../Options/GroupedMultiSelect';
import { GroupedSingleSelect } from '../../Options/GroupedSingleSelect';
// utils
import { getGroupedOptionsValue, onMultiSelectChange, onSingleSelectChange } from '../../Options/utils';
// components
import { ConditionalField } from './ConditionalField';
import { RenderSelectedItem } from './RenderSelectedItem';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const sx = {
  height: '100%',
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    pl: 1,
  },
};

const CustomComponent = (props: GridRenderCellParams) => {
  const apiRef = useGridApiContext();
  const { id, value, field, colDef } = props;
  const columnDef = colDef as any;
  const groupedOptions: GroupedOption[] = useGroupedOptions(columnDef?.valueOptions ?? [], {
    leadingQuestionId: columnDef?.leadingQuestionId,
    rowId: id ?? '*',
    questionId: columnDef?.field,
  });
  const { groupedOptionsVariant } = (colDef as any)?.columnType as QuestionTypeGroupedOptions;
  const [
    open,
    setOpen,
  ] = useState(true);

  const multiple = [
    GroupedOptionsVariant.SingleGroupMultiSelect,
    GroupedOptionsVariant.MultiGroupMultiSelect,
  ].includes(groupedOptionsVariant);

  const multiSelect = groupedOptionsVariant === GroupedOptionsVariant.MultiGroupMultiSelect;

  const handleChange = async (response: QuestionGroupedOption[] | null) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: response,
    });
  };

  const handleClose: MenuProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      if (apiRef.current.getCellMode(id, field) === GridCellModes.Edit) {
        apiRef.current.stopCellEditMode({ id, field });
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (apiRef.current.getCellMode(id, field) === GridCellModes.Edit) {
        apiRef.current.stopCellEditMode({ id, field, ignoreModifications: true });
      }
      setOpen(false);
    }
  };

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxWidth: 500,
        overflow: 'auto',
      },
      onKeyDown: handleKeyDown,
    },
  };

  const selected = getGroupedOptionsValue(value, multiple);

  const content = multiple ? (
    <GroupedMultiSelect
      value={value as QuestionGroupedOption[]}
      groupedOptions={groupedOptions}
      selectedOptions={selected as string[]}
      disabled={false}
      onOptionChange={async (options, lastSelected) => {
        const response = onMultiSelectChange(options, groupedOptions, multiSelect, lastSelected);
        handleChange(response);
      }}
      sx={sx}
      MenuProps={{
        ...menuProps,
        onClose: handleClose,
      }}
      autoFocus
      fullWidth
      open={open}
    />
  ) : (
    <GroupedSingleSelect
      value={value as QuestionGroupedOption[]}
      groupedOptions={groupedOptions}
      selectedOption={selected as string}
      disabled={false}
      onOptionChange={async option => {
        const response = onSingleSelectChange(option, groupedOptions);
        await handleChange(response);
      }}
      sx={sx}
      MenuProps={{
        ...menuProps,
        onClose: handleClose,
      }}
      onClose={() => handleClose({}, 'backdropClick')}
      autoFocus
      fullWidth
      open={open}
      multiple={multiple}
    />
  );

  return content;
};

export const GroupedOptions = (params: GridRenderEditCellParams<any, string>) => <CustomComponent {...params} />;

export const RenderGroupedOptionsCell = (params: GridRenderCellParams<any, GroupedOption[]>) => {
  const { value, colDef } = params;
  const options: Option[] = (colDef as any)?.valueOptions?.flatMap((group: GroupedOption) => group.options) ?? [];
  const selectedOptionIds = flatMap(value, 'options').map(opt => opt.id);
  const selectedOptions = options.filter(optionValue => selectedOptionIds.includes(optionValue.id));
  return (
    <ConditionalField params={params}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selectedOptions?.map(
          option =>
            option && (
              <RenderSelectedItem
                key={option.id}
                option={option}
                inCell={true}
              />
            ),
        )}
      </Box>
    </ConditionalField>
  );
};
