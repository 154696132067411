import {
  addDays as dateFnsAddDays,
  differenceInDays as dateFnsDifferenceInDays,
  isAfter as dateFnsIsAfter,
  isBefore as dateFnsIsBefore,
  isSameDay as dateFnsIsSameDay,
  format,
  formatDistanceToNow,
  isValid,
} from 'date-fns';

type Value = Date | string | number | null;

export const parseValidDate = (value: any): Date | undefined => {
  if (
    !value ||
    (typeof value === 'string' && value.trim().length <= 2) ||
    (typeof value === 'number' && value.toString().length === 1)
  ) {
    return undefined;
  }

  if (value instanceof Date) return isValid(value) ? value : undefined;

  const date = new Date(value);
  return isValid(date) ? date : undefined;
};

export function formatDate(date: Value, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';
  return date ? format(new Date(date), fm) : '';
}

export function formatDateTime(date: Value, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';
  return date ? format(new Date(date), fm) : '';
}
export const formatDateToLocaleDateString = (
  dateString: string,
  locale: string = 'en-GB',
  options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  },
) => {
  const isValidDate = !isNaN(Date.parse(dateString));
  if (!isValidDate) {
    throw new Error('Invalid date string');
  }
  const dateObj = new Date(dateString);
  return dateObj.toLocaleDateString(locale, options);
};

export function toNow(date: Value) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function differenceInDays(start: Value, end: Value) {
  return start && end ? dateFnsDifferenceInDays(new Date(end), new Date(start)) : 0;
}

export function addDays(date: Value, days: number) {
  return date ? dateFnsAddDays(new Date(date), days) : '';
}

export function isSameDay(date1: Value, date2: Value) {
  return date1 && date2 ? dateFnsIsSameDay(new Date(date1), new Date(date2)) : false;
}

export function isBefore(date1: Value, date2: Value) {
  return date1 && date2 ? dateFnsIsBefore(new Date(date1), new Date(date2)) : false;
}

export function isAfter(date1: Value, date2: Value) {
  return date1 && date2 ? dateFnsIsAfter(new Date(date1), new Date(date2)) : false;
}

export function isBetween(date1: Value, date2: Value, date3: Value) {
  return date1 && date2 && date3
    ? dateFnsIsAfter(new Date(date1), new Date(date2)) && dateFnsIsBefore(new Date(date1), new Date(date3))
    : false;
}

export function calculateDaysUntilEndDate(endDate: Date): number {
  const currentDate = new Date();
  const timeDifference = endDate.getTime() - currentDate.getTime();
  return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
}
