import { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Box, InputBase, Paper, Popper, Stack, Typography } from '@mui/material';
import { GridCellModes, GridRenderCellParams, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';

const DEFAULT_MAX_CHARACTER_LENGTH = 100;

export const CustomComponent = (props: GridRenderCellParams) => {
  const { id, field, colDef, hasFocus } = props;
  const [
    anchorEl,
    setAnchorEl,
  ] = useState<HTMLElement | null>();
  const [
    inputRef,
    setInputRef,
  ] = useState<HTMLInputElement | null>(null);
  const apiRef = useGridApiContext();
  const cell = apiRef.current.getCellElement(id, field);
  const cellHeight = cell?.clientHeight ? cell?.clientHeight - 10 : undefined;
  const currentValue = apiRef.current.getCellValue(id, field) || '';
  const isInEditMode = apiRef.current.getCellMode(id, field) === GridCellModes.Edit;
  const [
    valueState,
    setValueState,
  ] = useState(currentValue);

  const maxCharacterLength = (props.colDef as any)?.columnType?.rules?.limit ?? DEFAULT_MAX_CHARACTER_LENGTH;
  useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [
    hasFocus,
    inputRef,
  ]);

  useEffect(() => {
    if (apiRef.current) {
      const currentValue = apiRef.current.getCellValue(id, field) || '';
      setValueState(currentValue);
    }
    if (inputRef) {
      inputRef.focus();
      inputRef.selectionStart = inputRef.value.length;
      inputRef.selectionEnd = inputRef.value.length;
    }
  }, [
    inputRef,
  ]);

  const handleRef = (el: HTMLElement | null) => setAnchorEl(el);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValueState(newValue);
    apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event);
  };
  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    // avoids triggering data grid cell  click all the time slowing down the component
    if (event && !!event.key && event.key !== 'Escape' && event.key !== 'Tab' && !event.ctrlKey && !event.metaKey) {
      event.stopPropagation();
    }
  };
  return (
    <Box sx={{ position: 'absolute', alignSelf: 'flex-start' }}>
      <Box
        ref={handleRef}
        sx={{
          height: 1,
          width: colDef.width,
          display: 'block',
          position: 'absolute',
          top: 0,
          zIndex: 1500,
        }}
      />
      {anchorEl && (
        <Popper
          open
          anchorEl={anchorEl}
          placement="bottom-start"
          sx={{
            zIndex: 1500,
            minWidth: anchorEl.clientWidth,
            minHeight: cellHeight,
          }}
        >
          <Paper
            elevation={1}
            sx={{
              p: 1,
              minWidth: colDef.width,
              minHeight: cellHeight,
              zIndex: 1500,
            }}
          >
            <Box
              onClick={e => e.stopPropagation()}
              onKeyDown={handleOnKeyDown}
            >
              <InputBase
                data-testid="grid-edit-rich-text-input"
                disabled={!isInEditMode ? true : undefined}
                multiline
                rows={10}
                inputProps={{
                  maxLength: maxCharacterLength,
                }}
                value={valueState}
                sx={{
                  textarea: {
                    resize: 'both',
                    minWidth: colDef.width,
                    maxWidth: '100%',
                    position: 'relative',
                    minHeight: `${cellHeight}px`,
                    height: `50px`,
                    maxHeight: '350px',
                  },
                  width: '100%',
                }}
                autoFocus={false}
                onChange={handleOnChange}
                inputRef={ref => setInputRef(ref)}
              />
            </Box>
            <Stack>
              <Typography variant="body1">
                {props?.value?.length}/{maxCharacterLength}
              </Typography>
            </Stack>
          </Paper>
        </Popper>
      )}
    </Box>
  );
};

export const EditTextArea = (params: GridRenderEditCellParams<any, string>) => <CustomComponent {...params} />;
