/**
 * All functions are used in Editor.tsx. It's handling different parts of the component
 */

/**
 * This function helps to find the index of the first different character of two strings.
 */
export const getFirstDifferenceIndexOfTwoStrings = (a: string, b: string) => {
  let i = 0;
  if (a === b) return -1;
  while (a[i] === b[i]) i++;
  return i;
};

/**
 * This function helps to find the index of the last different character of two strings.
 * It's the reverse function of getFirstDifferenceIndexOfTwoStrings().
 */
export const getIndexOfLastDifferenceOfTwoStrings = (a: string, b: string) => {
  const aReversed = a.split('').reverse().join('');
  const bReversed = b.split('').reverse().join('');
  const reversedIndex = getFirstDifferenceIndexOfTwoStrings(aReversed, bReversed);
  const lengthOfAText = a.length;
  return lengthOfAText - reversedIndex;
};

/**
 * React Quill Editor transforms text into HTML Tag annotated text. This function filters HTML Tags out and
 * giving back the length of the text.
 */
export const getEditorOptimizedCharacterLength = (text: string | undefined) => {
  const HTMLTagRegex = /(<([^>]+)>)/gi;
  // new line without any character is placed in editor like this: <p><br></p>
  const newLineWithoutCharactersReplacedText = text?.replaceAll('<p><br></p>', ' ');
  // each paragraph is also one character, because of that replace all paragraph ends with one space: </p>
  const paragraphReplacedText = newLineWithoutCharactersReplacedText?.replaceAll('</p>', ' ');
  // First replacing the end of ordered list: </li></ol>
  const orderedListEndReplacedText = paragraphReplacedText?.replaceAll('</li></ol>', ' ');
  // Then afterwards each list ends are always one character: </li>
  const orderedListLineEndReplacedText = orderedListEndReplacedText?.replaceAll('</li>', ' ');
  // Unordered list has no united end, so we can just count each ends as one character: </u>
  const unorderedListEndReplacedText = orderedListLineEndReplacedText?.replaceAll('</li>', ' ');
  // because HTML tags are not counted as characters
  const countingTrimmedText = unorderedListEndReplacedText?.replaceAll(HTMLTagRegex, '');
  // Now minus 1 because we counted all closing </p> as character, but the last one is just the closing one.
  return countingTrimmedText?.length ? countingTrimmedText?.length - 1 : 0;
};
/**
 * This function is used to sanitise float number of string to allowed format
 */
export const sanitiseNumericInput = (value: string) => {
  return value
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*?)\..*/g, '$1')
    .replace(/^0[^.]/, '0');
};

export const generateNumericPlaceholder = (decimalPlaces: number = 0) =>
  decimalPlaces <= 0 ? '1234' : `1234.${'9'.repeat(decimalPlaces)}`;
