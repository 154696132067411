// react
import { useCallback, useState } from 'react';
// 3rd lib
import { FileRejection } from 'react-dropzone';
// api
import { useDeleteAttachment } from '@/graphql/response/mutations/deleteAttachment';
import { useIsSkipSave } from '@/store/response';
// types
import {
  ExtendFile,
  NumberOrString,
  QuestionTypeAttachmentRules,
  ResponseAttachment,
  ResponseStatus,
} from '@/types/response';
// utils
import { toAttachment, toExtendFile } from '@/utils/response';
import { FileUploader } from '../../FileUploader';
// utils
import { uploadMultipleFiles } from '../../FileUploader/utils';
// components
import Form from './Form';
// types
import { QuestionInputProps } from './types';

type QuestionUploadProps = QuestionInputProps<string | undefined> & {
  attachments?: ResponseAttachment[];
  rowId?: NumberOrString;
  rules?: QuestionTypeAttachmentRules | null;
};

export default function QuestionUpload({
  attachments,
  onChange,
  id,
  disabled = false,
  rowId,
  complexQuestionChild,
  title,
  rules,
}: QuestionUploadProps) {
  const [
    rejectedFiles,
    setRejectedFiles,
  ] = useState<FileRejection[]>([]);
  const [
    trigger,
  ] = useDeleteAttachment();

  const maxNumberOfAttachments = rules?.numberOfAttachments ?? Infinity;
  const actualNumberOfAttachments = attachments?.length ?? 0;

  const isPreview = useIsSkipSave();

  const getStatus = (files: ResponseAttachment[]) =>
    files.length === 0 ? ResponseStatus.Unanswered : ResponseStatus.Answered;

  const handleDropMultiFile = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setRejectedFiles(fileRejections);

      let files = [
        ...(attachments || []),
        ...(acceptedFiles?.map(file => toAttachment(file)) || []),
      ];

      // upload files to storage
      const { accepted, rejected } = await uploadMultipleFiles(acceptedFiles, id, rowId, isPreview);

      // update status (loading => false)
      files = [
        ...(attachments || []),
        ...(accepted?.map(file => toAttachment(file, false)) || []),
      ];
      onChange?.(undefined, getStatus(files), files);

      setRejectedFiles([
        ...fileRejections,
        ...rejected,
      ]);
    },
    [
      attachments,
      id,
      onChange,
    ],
  );

  const handleRemoveFile = async (inputFile: ExtendFile) => {
    if (inputFile.attachmentId) {
      try {
        if (!isPreview) {
          const res = await trigger({
            variables: { id: inputFile.attachmentId },
          });
          if (res?.errors) throw new Error('Deletion failed !');
        }

        const files = attachments?.filter(file => file.id !== toAttachment(inputFile).id) || [];

        onChange?.(undefined, getStatus(files), files);
      } catch (e) {
        // [hmachaao]: notify with notistack
        console.error(e);
      }
    }
  };

  return (
    <Form
      complexQuestionChild={complexQuestionChild}
      title={title}
    >
      <FileUploader
        disabled={disabled}
        multiple
        files={toExtendFile(attachments)}
        rejectedFiles={rejectedFiles}
        onDrop={handleDropMultiFile}
        onRemove={handleRemoveFile}
        onDelete={rejected => {
          setRejectedFiles(rejectedFiles?.filter(file => file !== rejected));
        }}
        sx={{ minWidth: 0 }}
        maxFiles={maxNumberOfAttachments}
        limitReached={actualNumberOfAttachments >= maxNumberOfAttachments}
      />
    </Form>
  );
}
