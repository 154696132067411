// mui
import { Typography } from '@mui/material';
// data grid
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
// component
import { ConditionalField } from './ConditionalField';

const MAX_HEIGHT_REM = 9.375; // 150px / 16px = 9.375rem

export const DefaullCeltRenderer = (params: GridRenderCellParams) => {
  const { value, colDef } = params;

  return (
    <ConditionalField params={params}>
      <div
        style={{
          position: 'relative',
          maxHeight: `${MAX_HEIGHT_REM}rem`,
          maxWidth: colDef.computedWidth,
          overflow: 'hidden',
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography
          style={{
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
            overflow: 'hidden',
            maxHeight: `${MAX_HEIGHT_REM}rem`,
            maxWidth: colDef.computedWidth,
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 6,
          }}
        >
          {value}
        </Typography>
      </div>
    </ConditionalField>
  );
};
