import { Option } from '@/types/response';
import { Chip, Tooltip, Typography } from '@mui/material';

type RenderSelectedItemProps = {
  option: Option;
  inCell: boolean;
};

export function RenderSelectedItem({ option, inCell }: RenderSelectedItemProps) {
  if (!option) return <></>;

  return (
    <Tooltip
      title={inCell && option?.name?.length > 30 ? option?.name : ''}
      arrow
      placement="bottom-end"
    >
      <Chip
        label={
          <Typography
            fontSize={13}
            align="left"
            sx={{
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              lineHeight: 1.1,
            }}
          >
            {option.name}
          </Typography>
        }
      />
    </Tooltip>
  );
}
