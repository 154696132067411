// types
// next
import { useTranslation } from 'next-i18next';
// react-dropzone
import { ErrorCode } from 'react-dropzone';
// icons
import { Close, UploadFile } from '@mui/icons-material';
// mui
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { RejectdFilesProps } from '../types';
// utils
import { fileData, formatFileSize } from '../utils';

export default function RejectionFiles({ rejectedFiles, maxSize, onDelete }: RejectdFilesProps) {
  const { t } = useTranslation('questionnaire', {
    keyPrefix: 'questionnaire.uploader.errors',
  });

  if (!rejectedFiles) {
    return null;
  }
  return (
    <Stack>
      {rejectedFiles.map(rejected => {
        const { key, name, size } = fileData(rejected.file);

        return (
          <Stack
            key={key}
            spacing={2}
            direction="row"
            alignItems="center"
            sx={{
              p: 1,
              '& > *': {
                color: 'error.main',
              },
            }}
          >
            <UploadFile />

            <Stack
              flexGrow={1}
              sx={{ minWidth: 0 }}
            >
              <Typography
                variant="subtitle2"
                noWrap
              >
                {name} - {formatFileSize(size)}
              </Typography>

              {rejected.errors.map(error => (
                <Box
                  key={error.code}
                  component="span"
                  sx={{ typography: 'caption' }}
                >
                  {error.code === ErrorCode.FileTooLarge
                    ? t('FileTooLarge', { size: formatFileSize(maxSize) })
                    : error.code === ErrorCode.FileInvalidType
                      ? t('FileInvalidType')
                      : error.message}
                </Box>
              ))}
            </Stack>

            <IconButton
              edge="end"
              size="small"
              color="error"
              onClick={() => onDelete?.(rejected)}
            >
              <Close />
            </IconButton>
          </Stack>
        );
      })}
    </Stack>
  );
}
