// lodash
import { isNull } from 'lodash';
// types
import { CrossValidation, CrossValidations, NumberOrString, QuestionResponse } from '@/types/response';
// utils
import { keyFromIds } from './response';

const operators = new Set([
  '+',
  '-',
  '*',
  '/',
]);

function transPostfix(input: NumberOrString[]): NumberOrString[] {
  const transformedArray = input.map(item => {
    if (Array.isArray(item)) {
      return item.join('-');
    } else {
      return item;
    }
  });
  return transformedArray;
}

const parseCrossPostfix = (exporession: string) => {
  return transPostfix(JSON.parse(exporession));
};

const isNullOrNaN = (value: any) => value === undefined || isNaN(value);

const isValid = (response: number, value: number, operand: string, margin?: number | null) => {
  const percent = (margin: number) => (margin / 100) * value;

  switch (operand) {
    case 'lt':
      return response < value;
    case 'lte':
      return response <= value;
    case 'gt':
      return response > value;
    case 'gte':
      return response >= value;
    case 'eq':
      return margin ? value <= response + percent(margin) && value >= response - percent(margin) : response === value;
    case 'neq':
      return response !== value;
    default:
      throw new Error(`Invalid operand: ${operand}`);
  }
};

export const evaluateCrossValidation = (
  responses: Record<NumberOrString, QuestionResponse>,
  validation: CrossValidation | undefined,
  content: number,
) => {
  const stack: number[] = [];

  if (!validation || isNull(content) || Object.keys(responses).length === 0) return undefined;

  const tokens = validation?.crossValidation ?? [];
  for (const token of tokens) {
    if (!operators.has(token as string)) {
      // if token is constant
      if (typeof token === 'number') stack.push(token);
      // if the token is an id, push it's value onto the stack
      else stack.push(parseFloat(responses[token]?.content));
    } else {
      // if the token is an operator, pop the top two operands from the stack,
      // apply the operator, and push the result back onto the stack.
      const operand2 = stack.pop() as number;
      const operand1 = stack.pop() as number;

      if (isNullOrNaN(operand1) || isNullOrNaN(operand2)) {
        return undefined;
      }

      switch (token) {
        case '+':
          stack.push(operand1 + operand2);
          break;
        case '-':
          stack.push(operand1 - operand2);
          break;
        case '*':
          stack.push(operand1 * operand2);
          break;
        case '/':
          if (operand2 == 0) return 'divisionByZero';
          stack.push(operand1 / operand2);
          break;
        default:
          // we should never end up here !
          throw new Error('Invalid operator');
      }
    }
  }

  if (stack.length !== 1) return undefined;

  return isValid(content, stack[0], validation.operand, validation.margin) ? undefined : validation.errorMessage;
};

export const toCrossValidation = (
  data: CrossValidations,
  idSet: Set<NumberOrString>,
): Map<NumberOrString, CrossValidation> => {
  const crossValidation = new Map<NumberOrString, CrossValidation>();

  if (!data || data?.crossValidations?.length < 1) return crossValidation;

  data?.crossValidations?.forEach(exp => {
    const { leadingQuestionId, rowId, questionId, postfix, operand, errorMessage, margin } = exp;
    if (idSet.has(keyFromIds(leadingQuestionId)) || idSet.has(keyFromIds(questionId))) {
      crossValidation.set(keyFromIds(leadingQuestionId, rowId, questionId), {
        crossValidation: parseCrossPostfix(postfix),
        operand,
        margin,
        errorMessage,
      });
    }
  });

  return crossValidation;
};
