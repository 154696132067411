// react
import { PropsWithChildren } from 'react';
// store
import { useDisplayChildQuestion } from '@/store/response';
// types
import { NumberOrString } from '@/types/response';
// icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// data grid
import { GridRenderCellParams } from '@mui/x-data-grid-premium';

type Props = PropsWithChildren<{ params: GridRenderCellParams }>;

export const ConditionalField = ({ params, children }: Props) => {
  const { id, field, colDef } = params;
  const rowId = id;
  const questionId = field;
  const leadingQuestionId = (colDef as any).leadingQuestionId as NumberOrString;

  const { available } = useDisplayChildQuestion(leadingQuestionId, rowId, questionId);

  return available === false ? <LockOutlinedIcon /> : <>{children}</>;
};
