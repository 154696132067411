// api
import { useGetMetadata } from '@/graphql/response/queries/metadata';
// types
import { QuestionnaireInstanceStatus, QuestionnaireMetadata } from '@/types/response';

const questionnaireMetadataGuard = (data: any): data is QuestionnaireMetadata => {
  return data && typeof data.status !== 'undefined';
};

export const useMetadata = (shouldFetch = true) => {
  const { data, loading, error, refetch } = useGetMetadata(shouldFetch);

  const metadata = questionnaireMetadataGuard(data) ? data : undefined;

  return {
    metadata,
    loading,
    error,
    status: metadata ? metadata.status : undefined,
    submitted: metadata ? metadata.status === QuestionnaireInstanceStatus.Submitted : false,
    refetch,
  };
};
