// lodash
import { isEmpty } from 'lodash';
// react
import { useEffect } from 'react';
// next
import { useTranslation } from 'react-i18next';
// store
import { useExpression, useResponseActions } from '@/store/response';
// types
import { NumberOrString, ResponseStatus } from '@/types/response';
// mui
import { Box, TextField } from '@mui/material';
// data grid
import { GridRenderCellParams } from '@mui/x-data-grid-premium';

export const AutoComputedField = (props: GridRenderCellParams) => {
  const { setResponse } = useResponseActions();
  const { t } = useTranslation('questionnaire', {
    keyPrefix: 'questionnaire.response.question',
  });

  const { value, id, field, colDef } = props;
  const rowId = id;
  const questionId = field;
  const leadingQuestionId = (colDef as any).leadingQuestionId as NumberOrString;
  const rules = (colDef as any).rules;

  const { content: computedContent } = useExpression(
    {
      leadingQuestionId,
      rowId,
      questionId,
    },
    (colDef as any).blankOptions,
  );

  const isContentNaN = isNaN(parseFloat(computedContent));

  useEffect(() => {
    if ((!isEmpty(computedContent) || !isEmpty(value)) && computedContent !== value)
      setResponse({
        leadingQuestionId,
        questionId,
        rowId,
        content: isContentNaN
          ? null
          : rules?.decimals != null
            ? parseFloat(computedContent).toFixed(rules.decimals)
            : computedContent,
        status: isContentNaN ? ResponseStatus.Unanswered : ResponseStatus.Answered,
      });
  }, [
    computedContent,
  ]);

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: colDef.width || '100%',
        maxWidth: colDef.computedWidth,
        overflowX: 'hidden',
      }}
    >
      <TextField
        sx={{
          '& fieldset': { border: 'none' },
          '& .MuiOutlinedInput-root': { padding: 0 },
          width: '100%',
        }}
        value={
          isContentNaN
            ? t('autoCalculated')
            : rules?.decimals != null
              ? parseFloat(computedContent).toFixed(rules.decimals)
              : computedContent
        }
        placeholder={t('autoCalculated')}
        InputProps={{
          inputProps: {
            readOnly: true,
            sx: {
              textAlign: 'right',
              fontSize: 14,
              padding: 0,
              display: 'block',
              flexWrap: 'wrap',
              overflow: 'hidden',
              WebkitBoxOrient: 'horizontal',
              textOverflow: 'ellipsis',
            },
          },
        }}
      />
    </Box>
  );
};
