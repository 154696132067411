// types
import { flatMap } from 'lodash';
import { GroupedOption, Option, QuestionGroupedOption } from '@/types/response';
// mui
import { Box, ListSubheaderProps, ListSubheader as MuiListSubheader } from '@mui/material';
import { RenderSelectedItem } from '../GridView/cells/RenderSelectedItem';

export const renderValues = (options: Option[]) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {options.map(option => {
        return (
          <RenderSelectedItem
            key={option.id}
            option={option}
            inCell={false}
          />
        );
      })}
    </Box>
  );
};

export const flattenedOptions = (groupedOptions: GroupedOption[]) => flatMap(groupedOptions, group => group.options);

export const getOptionsByIds = (options: Option[], ids: string[]) => {
  if (ids?.length === 0) return [];
  return options?.filter(option => ids?.includes(option?.id));
};

export const getOptionById = (options: Option[], id: string) => {
  return options?.find(option => option.id == id);
};

export const ListSubheader = (props: ListSubheaderProps & { muiSkipListHighlight: boolean }) => {
  // eslint-disable-next-line no-unused-vars
  const { muiSkipListHighlight, ...other } = props;
  return <MuiListSubheader {...other} />;
};

export const getGroupedOptionsValue = (value: QuestionGroupedOption[] | null | undefined, multiple?: boolean) => {
  if (!value || !Array.isArray(value)) return multiple ? [] : '';
  return (flatMap(value, 'options') as Option[]).map(option => option.id);
};

export const getGroupByOption = (
  groupedOptions: GroupedOption[],
  option: Option | undefined,
): { id: string; name: string } | undefined => {
  const foundGroup = groupedOptions.find(group => group.options.some(opt => opt.id === option?.id));
  return foundGroup ? { id: foundGroup.id, name: foundGroup.name } : undefined;
};
export const onMultiSelectChange = (
  options: Option[],
  groupedOptions: GroupedOption[],
  multiSelect: boolean | undefined,
  lastOption?: Option,
) => {
  const groups = new Map<string, { name: string; options: unknown[] }>();
  const response: any = [];

  if (!multiSelect && options.length) {
    // verify if the last selected option belongs to the same group as the previously selected options.
    // If they do not belong to the same group, reset the selection and include the last selected option.

    const groupId = getGroupByOption(groupedOptions, lastOption)?.id;
    options = options.filter(option => getGroupByOption(groupedOptions, option)?.id === groupId);
  }

  options?.forEach(option => {
    const groupOpt = getGroupByOption(groupedOptions, option);
    if (groupOpt) {
      if (groups.has(groupOpt.id)) groups.get(groupOpt.id)?.options.push({ ...option, type: 'multi' });
      else
        groups.set(groupOpt.id, {
          name: groupOpt.name,
          options: [
            { ...option, type: 'multi' },
          ],
        });
    }
  });

  groups.forEach((value, key) => {
    response.push({ group: key, name: value.name, options: value.options });
  });

  return response;
};

export const onSingleSelectChange = (option: Option | undefined, groupedOptions: GroupedOption[]) => {
  if (!option) return null;
  // determine the group to which the selected option belongs.
  const groupOpt = getGroupByOption(groupedOptions, option);
  const response = groupOpt
    ? [
        {
          group: groupOpt.id,
          name: groupOpt.name,
          options: [
            { ...option, type: 'single' },
          ],
        },
      ]
    : null;

  return response;
};

export const getValue = (value?: Option | Option[] | null, multiple?: boolean): string | string[] => {
  if (!value) return multiple ? [] : '';
  return Array.isArray(value) ? value.filter(opt => opt).map(option => option.id) : value.id;
};

export const toResponse = (options: Option[], optionsById: Record<string, Option>, selected: string[]) => {
  return options
    .filter(option => selected.includes(option?.id))
    .map(option => {
      return optionsById[option.id] ? { ...optionsById[option.id], type: 'multi' } : { ...option, type: 'multi' };
    });
};

export const toResponseSingleSelect = (option: Option | undefined) => {
  return option ? { ...option, type: 'single' } : undefined;
};
