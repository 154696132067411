import { Organization, RequestingAuthoritiesQuery } from '@/types/response';

export const requestingAuthoritiesList = (data: RequestingAuthoritiesQuery): Organization[] => {
  return data.requestingAuthorities.map(request => {
    const { source } = request;
    return {
      id: source.id,
      name: source.name,
    };
  });
};
