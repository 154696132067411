// date fns
import { format } from 'date-fns';
import { useLayoutEffect, useState } from 'react';
import { parseValidDate } from '@/utils/date';
import { Box } from '@mui/material';
import { GridCellModes } from '@mui/x-data-grid';
// data grid
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { DatePicker } from '@mui/x-date-pickers';
// components
import { ConditionalField } from './ConditionalField';

export const RenderDate = (params: GridRenderCellParams<any, Date>) => {
  const { value } = params;
  const date = parseValidDate(value);
  const content = date ? format(date, 'PP') : '';

  return <ConditionalField params={params}>{content}</ConditionalField>;
};

const CustomComponent = (props: GridRenderCellParams) => {
  const apiRef = useGridApiContext();
  const { id, field, colDef, hasFocus } = props;
  const rules = (colDef as any)?.columnType?.rules;
  const currentValue = apiRef.current.getCellValue(id, field) || '';
  const date = parseValidDate(currentValue);
  const [
    inputRef,
    setInputRef,
  ] = useState<HTMLInputElement | null>(null);

  useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef?.focus();
    }
  }, [
    hasFocus,
    inputRef,
  ]);

  const handleDateChange = (newValue: Date | null) => {
    let date = parseValidDate(newValue);
    if (date) {
      const minDate = parseValidDate(rules?.minDate);
      const maxDate = parseValidDate(rules?.maxDate);

      if ((minDate && date < minDate) || (maxDate && date > maxDate)) {
        date = undefined;
      }
    }

    const formattedDate = date ? format(date, 'PP') : null;

    apiRef.current.setEditCellValue({ id, field, value: formattedDate });
  };

  const onClose = () => {
    if (apiRef.current.getCellMode(id, field) === GridCellModes.Edit) {
      apiRef.current.stopCellEditMode({ id, field });
    }
  };

  return (
    <Box onClick={e => e.stopPropagation()}>
      <DatePicker
        data-testid="grid-edit-date-picker"
        label="Date"
        inputRef={ref => setInputRef(ref)}
        value={date}
        onChange={handleDateChange}
        onClose={onClose}
        minDate={parseValidDate(rules?.minDate)}
        maxDate={parseValidDate(rules?.maxDate)}
        sx={{
          '& fieldset': { border: 'none' },
          width: '100%',
        }}
      />
    </Box>
  );
};

export const DateFieldEdit = (params: GridRenderCellParams) => <CustomComponent {...params} />;
