import { responseClient } from '@/utils/apollo-client';
import { gql, useMutation } from '@apollo/client';

export function useDeleteAttachment() {
  const deleteAttachment = gql(`
  mutation deleteAttachment($id: Int!){
    deleteResponseAttachment(id: $id) {
        id
    }
  }
  `);

  return useMutation<{ id: number }>(deleteAttachment, {
    client: responseClient,
  });
}
