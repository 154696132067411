// lodash
import { differenceInCalendarDays, isAfter, isBefore, isValid } from 'date-fns';
import { sortBy } from 'lodash';
// types
import {
  Clause,
  ClauseKind,
  Condition,
  NumberOrString,
  Option,
  QuestionClause,
  QuestionGroupedOption,
  QuestionResponse,
  ResponseAttachment,
} from '@/types/response';
// utils
import { isArrayofGroupedOptions, isArrayofOptions, isObject, isOption, keyFromIds } from './response';

type ParsedValue = string | number | string[] | object[] | null;
type ClauseGroupedOptions = { group: string; options: string[] };

function parseJSONInput(jsonInput: string): ParsedValue | null {
  try {
    const parsed = jsonInput !== '' ? JSON.parse(jsonInput) : jsonInput;

    if (parsed === null) return null;

    if (typeof parsed === 'string' || typeof parsed === 'number') {
      // return the value directly if it is a string or number
      return parsed;
    } else if (Array.isArray(parsed)) {
      if (parsed.every(item => typeof item === 'string')) {
        // return as an array of strings
        return parsed as string[];
      } else if (parsed.every(item => typeof item === 'object')) {
        // return as an array of objects
        return parsed as object[];
      }
    }

    // If none of the above, throw an error
    throw new Error('Parsed value is of an unsupported type.');
  } catch (error) {
    console.error('Failed to parse JSON input:', error);
    return null;
  }
}

const areArraysEqual = (arr1: Option[], arr2: string[]): boolean => {
  const optionsSet = new Set(arr1.map(item => item.id));
  return arr2.every(id => optionsSet.has(id));
};

const areGroupedOptionsEqual = (
  groupedOptions1: QuestionGroupedOption[],
  groupedOptions2: ClauseGroupedOptions[],
): boolean => {
  if (groupedOptions1.length !== groupedOptions2.length) {
    return false;
  }

  return groupedOptions1.every(item1 => {
    const matchingGroup = groupedOptions2.find(item2 => item1.group === item2.group);
    if (!matchingGroup) {
      return false;
    }

    return areArraysEqual(item1.options, matchingGroup.options);
  });
};

const hasFiles = (attachments: ResponseAttachment[] | null | undefined) => {
  return attachments ? attachments.length > 0 : false;
};

export const isValidDate = (dateString: string) => {
  const date = new Date(dateString);
  return isValid(date);
};

const isDate = (value: any): value is string => {
  return typeof value === 'string' && isValidDate(value);
};

const isDateGreaterThan = (date1: Date, date2: Date) => {
  return isAfter(date1, date2);
};

const isDateLessThan = (date1: Date, date2: Date) => {
  return isBefore(date1, date2);
};

const isDateEqual = (date1: Date, date2: Date) => {
  return differenceInCalendarDays(date1, date2) === 0;
};

const isEq = (response: any, parsedValue: ParsedValue) => {
  // For rich text
  if (parsedValue === null && (response === '' || response === null)) return true;
  // For numeric qsts
  if (response === '' || response === null) return false;
  if (isDate(response) && isDate(parsedValue)) {
    const date1 = new Date(response);
    const date2 = new Date(parsedValue);
    return isDateEqual(date1, date2);
  }

  if (Array.isArray(response)) {
    if (isArrayofOptions(response)) {
      const casted = parsedValue as string[];
      return areArraysEqual(response, casted);
    }

    if (isArrayofGroupedOptions(response)) {
      const casted = parsedValue as ClauseGroupedOptions[];
      return areGroupedOptionsEqual(response, casted);
    }

    return false;
  }

  if (isObject(response)) {
    if (isOption(response)) {
      const id = (parsedValue as string[])?.[0];
      return response.id === id;
    }
  }

  return response == parsedValue;
};

const gt = (response: any, parsedValue: ParsedValue) => {
  if (parsedValue === null || response === '' || response === null) return false;

  if (isDate(response) && isDate(parsedValue)) {
    const date1 = new Date(response);
    const date2 = new Date(parsedValue);
    return isDateGreaterThan(date1, date2);
  }

  return response > parsedValue;
};

const gte = (response: any, parsedValue: ParsedValue) => {
  if (parsedValue === null || response === '' || response === null) return false;

  if (isDate(response) && isDate(parsedValue)) {
    const date1 = new Date(response);
    const date2 = new Date(parsedValue);
    return isDateEqual(date1, date2) || isDateGreaterThan(date1, date2);
  }

  return response >= parsedValue;
};

const lt = (response: any, parsedValue: ParsedValue) => {
  if (parsedValue === null || response === '' || response === null) return false;

  if (isDate(response) && isDate(parsedValue)) {
    const date1 = new Date(response);
    const date2 = new Date(parsedValue);
    return isDateLessThan(date1, date2);
  }

  return response < parsedValue;
};

const lte = (response: any, parsedValue: ParsedValue) => {
  if (parsedValue === null || response === '' || response === null) return false;

  if (isDate(response) && isDate(parsedValue)) {
    const date1 = new Date(response);
    const date2 = new Date(parsedValue);
    return isDateEqual(date1, date2) || isDateLessThan(date1, date2);
  }

  return response <= parsedValue;
};

const containsValue = (arr: Option[], value: string): boolean => {
  return arr.some(item => item.id === value);
};

const isContained = (arr1: Option[], arr2: string[]): boolean => {
  return arr2.some(value => containsValue(arr1, value));
};
const isGroupedOptionsContained = (
  groupedOptions1: QuestionGroupedOption[],
  groupedOptions2: ClauseGroupedOptions[],
): boolean => {
  return groupedOptions1.some(item1 => {
    const matchingGroup = groupedOptions2.find(item2 => item1.group === item2.group);
    if (!matchingGroup) {
      return false;
    }

    return isContained(item1.options, matchingGroup.options);
  });
};

const contains = (response: any, parsedValue: ParsedValue) => {
  if (Array.isArray(response)) {
    if (isArrayofOptions(response)) {
      const casted = parsedValue as string[];
      return isContained(response, casted);
    }

    if (isArrayofGroupedOptions(response)) {
      const casted = parsedValue as ClauseGroupedOptions[];
      return isGroupedOptionsContained(response, casted);
    }

    return false;
  }

  return false;
};

const evalCondition = (
  condition: Condition,
  response: any,
  attachments: ResponseAttachment[] | null | undefined,
): boolean => {
  return evaluateCondition(
    condition,
    isArrayofGroupedOptions(response) ? response.flatMap(item => item.options) : response,
    attachments,
  );
};

const evaluateCondition = (
  condition: Condition,
  response: any,
  attachments: ResponseAttachment[] | null | undefined,
): boolean => {
  const { operand, value } = condition;

  const parsedValue = parseJSONInput(value);

  switch (operand) {
    case 'eq':
      return isEq(response, parsedValue);
    case 'neq':
      return !isEq(response, parsedValue);
    case 'contains':
      return contains(response, parsedValue);
    case 'gt':
      return gt(response, parsedValue);
    case 'gte':
      return gte(response, parsedValue);
    case 'lt':
      return lt(response, parsedValue);
    case 'lte':
      return lte(response, parsedValue);
    case 'has files attached':
      return hasFiles(attachments);
    case 'has no files attached':
      return !hasFiles(attachments);
    default:
      // handle unsupported operand gracefully
      return false;
  }
};

const getResponse = (responses: Record<NumberOrString, QuestionResponse>, condition: Condition) => {
  const { leadingQuestionId, rowId, questionId } = condition;
  const key = keyFromIds(leadingQuestionId, rowId, questionId);
  return { response: responses[key], key };
};

export const evaluateQuestion = (
  conditions: Condition[],
  responses: Record<NumberOrString, QuestionResponse>,
  clauses: Map<NumberOrString, QuestionClause>,
): boolean => {
  const stack: boolean[] = [];

  for (const condition of conditions) {
    const { response, key } = getResponse(responses, condition);
    // a hidden question dependency should always be treated as false
    const isConditional =
      clauses.get(key)?.display === false || clauses.get(condition.leadingQuestionId ?? '')?.display === false;
    const conditionResult = isConditional
      ? false
      : evalCondition(condition, response?.content ?? null, response?.attachments ?? []);

    if (condition?.logicalOperator !== 'OR') {
      // If logicalOperator is 'AND' or unspecified, accumalte the result with previous condition
      if (stack.length !== 0) {
        const prevConditionResult = stack.pop();
        stack.push((prevConditionResult && conditionResult) ?? false);
      } else stack.push(conditionResult);
    } else {
      // If logicalOperator is an OR operator, push the result to the stack
      stack.push(conditionResult);
    }
  }
  return stack.some(value => value === true);
};

export const toClauses = (
  clauses: Clause[],
  idSet: Set<NumberOrString>,
  dynamicRowMapping: Map<NumberOrString, { id: string; name: string }[]>,
  staticRowMapping: Map<NumberOrString, { id: string; name: string }[]>,
  previousClauses?: Map<NumberOrString, QuestionClause>,
): Map<NumberOrString, QuestionClause> => {
  const questionClauseMap = new Map<NumberOrString, QuestionClause>();

  function setClauseMapping(clause: Clause) {
    const { leadingQuestionId, rowId, questionId, optionId } = clause;

    const { key, kind } = determineClauseType(leadingQuestionId, rowId, questionId, optionId);

    if (idSet.has(keyFromIds(leadingQuestionId)) || idSet.has(keyFromIds(questionId))) {
      questionClauseMap.set(key, {
        conditions: sortBy(clause.conditions as Condition[], 'index'),
        // we set the previous display of the clause and fallback to false if it is new condition
        display: previousClauses?.get(key)?.display ?? false,
        kind,
        leadingQuestionId: leadingQuestionId as NumberOrString,
        rowId: rowId as NumberOrString,
        questionId: questionId as NumberOrString,
        optionId: optionId as NumberOrString,
      });
    }
  }

  clauses.forEach(clause => {
    setClauseMapping(clause);
    const leadingQuestionId = clause.leadingQuestionId;
    const rowId = clause.rowId;

    if (!leadingQuestionId || !rowId) return;
    dynamicRowMapping.get(leadingQuestionId)?.forEach(dynamicRow => {
      const staticRow = staticRowMapping.get(leadingQuestionId)?.find(staticRow => staticRow.name === dynamicRow.name);
      if (staticRow && staticRow.id !== clause.rowId) return;
      const customClause = {
        ...clause,
        rowId: dynamicRow.id,
        conditions: clause?.conditions?.map(
          cond => cond && { ...cond, rowId: clause.rowId === cond?.rowId ? dynamicRow.id : cond?.rowId },
        ),
      };
      setClauseMapping(customClause);
    });
  });

  return questionClauseMap;
};

const determineClauseType = (
  leadingQuestionId?: NumberOrString | null,
  rowId?: NumberOrString | null,
  questionId?: NumberOrString | null,
  optionId?: NumberOrString | null,
) => {
  let kind = ClauseKind.Question;
  let key;

  if (optionId) {
    kind = ClauseKind.Option;
    key = keyFromIds(leadingQuestionId ?? '*', rowId ?? '*', questionId, optionId);
  } else if (leadingQuestionId && rowId && questionId) {
    kind = ClauseKind.ChildQuestion;
    key = keyFromIds(leadingQuestionId, rowId, questionId);
  } else if (leadingQuestionId && rowId) {
    key = keyFromIds(leadingQuestionId, rowId);
    kind = ClauseKind.Row;
  } else if (leadingQuestionId && questionId) {
    key = keyFromIds(leadingQuestionId, '*', questionId);
    kind = ClauseKind.Column;
  } else {
    key = keyFromIds(questionId);
  }

  return {
    key,
    kind,
  };
};
