// react
// i18n
import { useTranslation } from 'next-i18next';
import { useRef, useState } from 'react';
// types
import { Option } from '@/types/response';
// mui
import { FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import { RenderSelectedItem } from '../GridView/cells/RenderSelectedItem';
// components
import { OptionInputField } from './OptionInputField';
// utils
import { getOptionById, toResponseSingleSelect } from './utils';

type Props = {
  value: Option;
  options: Option[];
  selectedOption: string;
  disabled: boolean;
  onOptionChange: (options: Option | undefined, keepOpen?: boolean) => void;
} & SelectProps;

export const SingleSelect = ({ value, options, selectedOption, disabled, onOptionChange, ...rest }: Props) => {
  const { t } = useTranslation('questionnaire', {
    keyPrefix: 'questionnaire.placeholders',
  });
  const [
    open,
    setOpen,
  ] = useState(false);

  const optionRef = useRef<Option>();

  const handleClose = () => {
    if (optionRef.current?.isOther) setOpen(true);
    else setOpen(false);
    optionRef.current = undefined;
  };

  const handleOpen = () => {
    setOpen(true);
    optionRef.current = undefined;
  };

  const handleMenuItemClick = (optionId: string) => {
    if (optionId === selectedOption) {
      onOptionChange(undefined);
      setOpen(false);
    } else {
      const option = getOptionById(options, optionId);
      if (option?.isOther) {
        optionRef.current = option;
        onOptionChange(toResponseSingleSelect(option), true);
      } else {
        optionRef.current = undefined;
        setOpen(false);
        onOptionChange(toResponseSingleSelect(option));
      }
    }
  };

  const content = () => {
    const el: JSX.Element[] = [];
    options.forEach(option => {
      const checked = option.id === selectedOption;
      el.push(
        <MenuItem
          key={option.id}
          value={option.id}
          onClick={() => handleMenuItemClick(option.id)}
        >
          <ListItemText
            primary={option.name}
            sx={{
              whiteSpace: 'normal',
              wordBreak: 'break-word',
            }}
          />
        </MenuItem>,
      );
      if (option.isOther && checked)
        el.push(
          <OptionInputField
            key={`other-${option.id}`}
            description={value?.description ?? ''}
            option={option}
            onOptionEdit={option => {
              onOptionChange(toResponseSingleSelect(option));
            }}
          />,
        );
    });

    return el;
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{t('singleSelect')}</InputLabel>
      <Select
        label={t('singleSelect')}
        value={selectedOption ?? ''}
        onChange={(e: SelectChangeEvent<unknown>) => handleMenuItemClick(e.target.value as string)}
        onClose={handleClose}
        onOpen={handleOpen}
        disabled={disabled}
        displayEmpty
        renderValue={value => {
          const option = options.find(option => option.id === value);
          return (
            option && (
              <RenderSelectedItem
                option={option}
                inCell={false}
              />
            )
          );
        }}
        sx={{ flex: 1 }}
        data-testid="drop-down-select"
        open={open}
        {...rest}
      >
        {content()}
      </Select>
    </FormControl>
  );
};
