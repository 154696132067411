// react
import { PropsWithChildren } from 'react';
// mui
import { FormControl, FormControlProps, Typography } from '@mui/material';

type Props = FormControlProps &
  PropsWithChildren<{
    title?: string;
    complexQuestionChild?: boolean;
  }>;

export default function Form({ title, complexQuestionChild, children, ...rest }: Props) {
  return (
    <FormControl
      sx={{
        width: '100%',
        maxWidth: complexQuestionChild ? '100%' : '480px',
        gap: 1,
      }}
      {...rest}
    >
      {complexQuestionChild && <Typography variant="subtitle1">{title}</Typography>}
      {children}
    </FormControl>
  );
}
