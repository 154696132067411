// components
// react-dropzone
import { useDropzone } from 'react-dropzone';
// icons
import { UploadFile } from '@mui/icons-material';
// mui
import { Box, Stack, StackProps, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import RejectionFiles from './errors/RejectionFiles';
import MultiFilePreview from './preview/MultiFilePreview';
// types
import { UploadProps } from './types';

const StyledDropZone = styled('div')(({ theme }) => ({
  outline: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  padding: '24px 32px 32px 32px',
  borderRadius: theme.shape.borderRadius,
  border: `1px dashed ${theme.palette.secondary.light}`,
  '&:hover': {
    opacity: 0.7,
  },
}));

export default function FileUploader({
  id,
  disabled,
  error,
  helperText,
  files,
  rejectedFiles,
  onRemove,
  onDelete,
  sx,
  maxSize = 52428800, // 50 MB
  accept = {
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
    'application/vnd.ms-excel': [],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    'text/csv': [],
    'application/vnd.ms-powerpoint': [],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
  },
  placeholder = 'PDF, DOCX, XLSX, CSV, PPTX (max 50MB)',
  limitReached = false,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    disabled: disabled || limitReached,
    accept,
    maxSize,
    ...other,
  });

  const hasFiles = files && files.length > 0;
  const isError = isDragReject || !!error;

  return (
    <Box sx={{ width: 1, ...sx }}>
      <StyledDropZone
        {...getRootProps()}
        sx={{
          ...(isDragActive && {
            opacity: 0.7,
          }),
          ...(isError && {
            bgcolor: theme => alpha(theme.palette.error.main, 0.08),
          }),
          ...((disabled || limitReached) && {
            opacity: 0.5,
            pointerEvents: 'none',
          }),
        }}
      >
        <input
          data-testid="file-uploader-input"
          id={id}
          {...getInputProps()}
        />

        <Placeholder placeholder={placeholder as string} />
      </StyledDropZone>

      {helperText && helperText}

      <RejectionFiles
        rejectedFiles={rejectedFiles}
        onDelete={onDelete}
        maxSize={maxSize}
      />

      {hasFiles && (
        <MultiFilePreview
          files={files}
          disabled={disabled}
          onRemove={onRemove}
        />
      )}
    </Box>
  );
}

function Placeholder({ sx, placeholder, ...other }: StackProps) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{
        width: 1,
        ...sx,
      }}
      {...other}
    >
      <UploadFile fontSize="medium" />
      <Typography variant="body2">
        Click to upload
        <Typography
          variant="body2"
          component="span"
          sx={{
            mx: 0.5,
            color: 'text.secondary',
          }}
        >
          or drag and drop
        </Typography>
      </Typography>
      <Typography
        variant="caption"
        color="secondary"
      >
        {placeholder}
      </Typography>
    </Stack>
  );
}
