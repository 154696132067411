import { keyBy } from 'lodash';
import { useTranslation } from 'next-i18next';
import { Option } from '@/types/response';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectProps } from '@mui/material';
import { OptionInputField } from './OptionInputField';
import { getOptionsByIds, renderValues, toResponse } from './utils';

type Props = {
  value: Option[];
  options: Option[];
  selectedOptions: string[];
  disabled: boolean;
  onOptionChange: (options: Option[]) => void;
} & SelectProps;

export const MultiSelect = ({ value, options, selectedOptions, disabled, onOptionChange, ...rest }: Props) => {
  const optionsById = keyBy(value, 'id');
  const { t } = useTranslation('questionnaire', {
    keyPrefix: 'questionnaire.placeholders',
  });

  const content = () => {
    const el: JSX.Element[] = [];
    options.forEach(option => {
      const checked = !!optionsById[option.id];
      el.push(
        <MenuItem
          key={option.id}
          value={option.id}
          sx={{
            whiteSpace: 'normal',
            width: '100%',

            overflow: 'hidden',
          }}
        >
          <Checkbox
            size="small"
            checked={checked}
          />
          <ListItemText
            primary={option.name}
            sx={{
              whiteSpace: 'normal',
              wordBreak: 'break-word',
            }}
          />
        </MenuItem>,
      );
      if (option.isOther && checked)
        el.push(
          <OptionInputField
            key={`other-${option.id}`}
            description={optionsById[option.id]?.description ?? ''}
            option={option}
            onOptionEdit={option => {
              const opts = { ...optionsById, [option.id]: option };
              onOptionChange(toResponse(options, opts, selectedOptions));
            }}
          />,
        );
    });

    return el;
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{t('multiSelect')}</InputLabel>
      <Select
        label={t('multiSelect')}
        value={selectedOptions}
        onChange={e => {
          const selected = e.target.value as string[];
          onOptionChange(toResponse(options, optionsById, selected));
        }}
        disabled={disabled}
        renderValue={value => {
          return renderValues(getOptionsByIds(options, value as string[]));
        }}
        sx={{ flex: 1 }}
        multiple
        data-testid="drop-down-select"
        {...rest}
      >
        {content()}
      </Select>
    </FormControl>
  );
};
